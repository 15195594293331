* {
    margin: 0;
}

.wrapper {
    box-sizing: border-box;
    min-height: 100%;
    padding: 0 0 45px; /* the bottom margin is the negative value of the footer's height */
    position: relative;
}

body {
    height: 100%;
    z-index: -1;
}

footer {
    bottom: 0;
    height: 45px;
    left: 0;
    position: absolute;
    width: 100%;
}

.header-layer {
    z-index: 3;
}

.footer-layer {
    z-index: -1;
}

.nothing-display {
    text-align: center;
    margin-top: 20%;
}
