.prev {
    text-decoration: none;
    color: #009fda;
    font-size: 14px;
}

.wrap {
	width: auto;
	position: absolute;
    left: 0px;
    top: 30%;
}

.loader {
    margin: 15% 50%;
}