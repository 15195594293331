.footer-position {
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.footer-margin {
    margin: 0;
    padding: 0;
}

.footer {
    background: white;
    z-index: 10;
}