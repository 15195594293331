
.tl-title {
    padding-right: 3px;
	padding-left: 0px;
	margin-bottom: 20px;
	margin-top: 10px;
	border-bottom: #ccc 1px solid;
	text-align: center !important;
}

#button {
    padding-bottom: 5px;
}

.content {
    margin-top: 20%;
}

.loader {
    margin: 10% 45%;
}

.flex-container {
    min-height: 300px;
}

.desc {
    min-height: 200px;
}

.result-desc {
    text-align: center;
    margin: auto;
}

.result-err {
    text-align: left;
    margin: auto 20%;
    color: red;
}