.title {
    margin-left: 30px
}

.wrap {
	width: auto;
	position: absolute;
    left: 0px;
    top: 30%;
}

#button-next {
    padding-bottom: 5px;
}