.header-position {
    width: 50%;
    padding-left: 0px;
    padding-right: 20px;
}

.dropdown-toggle::after { 
    content: none; 
}

.o-header {
    border-bottom: 1px solid black !important;
}

.o-header-right {
    position: absolute;
    right: 20px !important;
    top: 20px !important;
}

.o-header-product {
    margin-left: 10px;
}

.logo-image {
    width: 120px;
}

.lang-dropdown {
    padding-right: 10px;
    border-right: 1px solid #0f204b;
    padding: 0 1.6rem 0 0;
}

@media screen and (max-width: 700px) {
    .o-header {
        height: 120px
    }
    .header-position {
        padding-bottom: 50px
    }
    .o-header-right {
        padding-top: 60px
    }
}