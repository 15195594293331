.intro-title {
    padding-right: 3px;
	padding-left: 0px;
	margin-bottom: 20px;
	margin-top: 10px;
	border-bottom: #ccc 1px solid;
	text-align: center !important;
}

#container {
    width: 100% !important;
}