#capability-whole-chart {
    z-index: 10;
    margin-bottom: -500px;
}

#capability-active-segment {
    z-index: -1;
}

#target-report-chart {
    z-index: 0;
    margin-bottom: -500px;
}

#target-target-level {
    z-index: 1;
}

.overall {
    margin: auto;
    padding-bottom: 44px !important;
}

#report-report-chart {
    z-index: 1;
    margin-bottom: -700px;
}

#report-target-level {
    z-index: 1;
}

#report-def-level {
    margin-top: -700px;
    z-index: 1;
}

#report-target-reached {
    margin-top: -700px;
    z-index: 1;
}

#report-target-reached-label {
    margin-top: -700px;
    z-index: 1;
}

#report-rec {
    margin-top: -700px;
    z-index: 1;
}

.next-level {
    color: #fff;
    background-color: #0f204b;
    border-color: #0f204b;
    font-size: 12px;
}

.rec-obj {
    text-align: center;
}

#legend {
    position: absolute;
    right: 0 !important;
    margin-top: -700px;
    z-index: -1
}

@media all and (max-width: 1200px) {
    #legend {
        left: 880px;
    }
}