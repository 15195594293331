#report-chart {
    z-index: -1;
    margin-bottom: -600px;
}

#target-level {
    z-index: 1;
}

.tl-title {
    padding-right: 3px;
	padding-left: 0px;
	margin-bottom: 20px;
	margin-top: 10px;
	border-bottom: #ccc 1px solid;
	text-align: center !important;
}

.prev {
    text-decoration: none;
    color: #009fda;
    font-size: 14px;
}

.wrap {
	width: auto;
	position: absolute;
    left: 0px;
    top: 30%;
}

#button-next {
    padding-bottom: 5px;
}

.content {
    margin-top: 20%;
}

.loader {
    margin: 70% 50%;
}

.flex-container {
    min-height: 600px;
}

.chart-desc {
    min-height: 600px;
}

.target-expl {
    text-align: center;
    margin: auto;
}